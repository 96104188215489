/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { usePixKeysContext } from "@/context/Pix";
import {
  getCreateKey,
  Hooks,
  setHeader,
  Validator,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Input } from "@hyperlocal/vital";
import InputMask from "react-input-mask";
import * as S from "./styles";
import { FetchState } from "./types";

type InsertKeyProps = {
  fetchState: FetchState;
  backToMyKeysSucess: () => void;
  createTokenRequest: () => void;
};

export function InsertKey({
  fetchState,
  backToMyKeysSucess,
  createTokenRequest,
}: InsertKeyProps) {
  const { dispatchFilter } = usePixKeysContext();
  const request = getCreateKey();
  const isMobile = Hooks.useMediaQuery("mobile");
  const [key, setNewKey] = useState("");

  const isNotEVP = request?.type !== "EVP";
  const isInvalidKey = key ? !Validator.checkPixKey(key) : true;
  const isDisabled = (isNotEVP && isInvalidKey) || !!fetchState.errorMessage;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatchFilter({
      type: "SET_NEW_PIX_KEY",
      payload: { newPixKey: e.target.value },
    });
    setNewKey(e.target.value);
  };

  useEffect(() => {
    setHeader({
      title: `Nova Chave`,
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: backToMyKeysSucess,
      },
    });
  }, [backToMyKeysSucess, isMobile]);

  return (
    <S.Wrapper>
      <div>
        <p>
          Você poderá receber, pagar e fazer transações com sua chave Pix, sem a
          necessidade de digitar seus dados, basta informar sua chave:
        </p>
        {isNotEVP && request?.mask ? (
          <>
            {/* @ts-ignore */}
            <InputMask
              mask={request?.mask}
              value={request?.key}
              onChange={handleChange}
              maskPlaceholder={null}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  label={request.title}
                  helper={fetchState.errorMessage ? "error" : "info"}
                  helperText={fetchState.errorMessage}
                />
              )}
            </InputMask>
          </>
        ) : (
          isNotEVP && (
            <Input
              type={"text"}
              label={request?.title}
              value={request?.key}
              onChange={(e) => handleChange(e)}
              helper={fetchState.errorMessage ? "error" : "info"}
              helperText={fetchState.errorMessage}
            />
          )
        )}
        {request?.type === "EVP" && fetchState.errorMessage ? (
          <p className="error">{fetchState.errorMessage}</p>
        ) : null}
      </div>
      <Button
        isLoading={fetchState.isFetching}
        disabled={isDisabled}
        onClick={createTokenRequest}
      >
        Cadastrar
      </Button>
    </S.Wrapper>
  );
}
