import { Avatar as VitalAvatar, Card as VitalCard } from "@hyperlocal/vital";
import styled, { css, StyledComponent } from "styled-components";

type TransactionType = "income" | "outcome";

export const TransactionItem = styled.li`
  all: unset;
`;

export const Card = styled(VitalCard as StyledComponent<any, any, {}, never>)`
  width: 100% !important;
  height: 73px;

  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.inset.xs};
  align-items: center;

  padding: ${({ theme }) => theme.spacings.squished.md};

  cursor: pointer;

  border-color: ${({ theme }) => theme.palette.neutral.light};
`;

type VitalAvatarProps = {
  type: TransactionType;
};

export const Avatar = styled(VitalAvatar)<VitalAvatarProps>`
  background: ${({ theme, type }) => {
    const color: Record<TransactionType, string> = {
      income: theme.palette.status.success.successLight,
      outcome: theme.palette.neutral.lighter,
    };

    return `${color[type] || color.outcome} !important`;
  }};

  & > svg {
    width: 24px;
    height: 24px;
  }

  & > svg > path {
    fill: ${({ theme, type }) => {
      const color: Record<TransactionType, string> = {
        income: theme.palette.secondary.darker,
        outcome: theme.palette.neutral.black,
      };

      return `${color[type] || color.outcome} !important`;
    }};
  }
`;

type ITransactionItemInfoProps = {
  position: "start" | "end";
};

export const TransactionItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.inset.lg};

  width: 100%;
`;

export const TransactionItemInfo = styled.div<ITransactionItemInfoProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ position }) =>
    position === "start" ? "flex-start" : "flex-end"};
  gap: ${({ theme }) => theme.spacings.inset.x3s};

  width: 100%;
  height: 100%;
`;

type TransactionItemHeadingType = "primary" | "secondary";

type ITransactionItemHeadingProps = {
  type?: TransactionItemHeadingType;
};

export const TransactionItemHeading = styled.span<ITransactionItemHeadingProps>`
  font-family: ${({ theme }) => theme.fonts.base};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  font-size: ${({ theme, type }) => {
    const fontSize: Record<TransactionItemHeadingType, string> = {
      primary: theme.fontSizes.x2s,
      secondary: theme.fontSizes.xs,
    };

    return fontSize[type] || theme.fontSizes.x2s;
  }};

  line-height: ${({ theme }) => theme.lineHeights.md};

  color: ${({ theme, type }) => {
    const color: Record<TransactionItemHeadingType, string> = {
      primary: theme.palette.neutral.darkest,
      secondary: theme.palette.secondary.darker,
    };

    return color[type] || theme.palette.neutral.dark;
  }};
`;

type TransactionItemAmountProps = {
  type: TransactionType;
};

export const TransactionItemAmount = styled.p<TransactionItemAmountProps>`
  ${({ theme: { fontSizes, fontWeights } }) => css`
    font-size: ${fontSizes.x2s}; // 14px
    font-weight: ${fontWeights.regular}; // 400
    line-height: 24px;
  `}

  ${({ theme: { breakpoints, fontSizes } }) =>
    breakpoints.desktop(`
      font-size: ${fontSizes.xs};     
  `)}

  color: ${({ theme }) => theme.palette.neutral.darker};

  /* color: ${({ theme: { palette }, type }) => {
    const color: Record<TransactionType, string> = {
      income: palette.secondary.darker,
      outcome: palette.status.error.errorDark,
    };

    return color[type] || palette.neutral.dark;
  }}; */
`;

export const TransactionItemDrawerContainer = styled.div`
  max-width: 433px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100% - 24px);
`;

export const TransactionItemDrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  margin-top: ${({ theme }) => theme.spacings.inset.lg};
`;

export const TransactionItemDrawerHeaderLabel = styled.small`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const TransactionItemDrawerHeaderTitle = styled.h6`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacings.inset.x3s};
`;

export const TransactionItemDrawerContent = styled.div`
  padding: ${({ theme }) => theme.spacings.inset.md} 0;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.md};
`;

export const Divider = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.palette.neutral.lighter};
`;

export const TransactionItemDetail = styled.div<{
  direction: "column" | "row";
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => props.direction};
  gap: ${({ theme }) => theme.spacings.inset.x3s};
`;

export const TransactionItemLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  color: ${({ theme }) => theme.palette.neutral.darkest};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const TransactionItemValue = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.palette.neutral.dark};
`;

export const TransactionItemDrawerFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.inset.x3s};

  margin: ${({ theme }) => theme.spacings.inset.md} 0;
`;

export const TransactionItemDrawerFooterMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  color: ${({ theme }) => theme.palette.neutral.dark};
  text-align: center;
`;

export const TransactionIcon = styled.div`
  width: 166px;
  height: 166px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.palette.primary.lighter};
  border-radius: 50%;

  margin-bottom: ${({ theme }) => theme.spacings.inset.lg};

  & div {
    position: absolute;
    z-index: 999;
    color: ${({ theme }) => theme.palette.neutral.white};

    width: 48px;
  }

  &:before {
    width: 124px;
    height: 124px;
    border-radius: 50%;

    content: "";

    background: ${({ theme }) => theme.palette.primary.light};
  }

  &:after {
    width: 80px;
    height: 80px;
    border-radius: 50%;

    content: "";
    position: absolute;
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;
