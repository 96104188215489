import { useCheckAppVersion, useGetReceipt } from "@/api";
import { NotFound } from "@/components/not-found";
import { P2PReceipt } from "@/components/p2p-receipt";
import { PaymentReceipt } from "@/components/payment-receipt";
import { PixReceipt } from "@/components/pix-receipt";
import { TedReceipt } from "@/components/ted-receipt";
import {
  ParcelProps,
  ReceiptOrigin,
  ReceiptType,
  TransactionMovement,
} from "@/types";
import { useParams } from "react-router-dom";

export const ReceiptRoot = (props: ParcelProps) => {
  const params = useParams<{
    receiptId: string;
    receiptType: ReceiptType;
    receiptOrigin: ReceiptOrigin;
    movement: TransactionMovement;
  }>();

  const receiptId = props.receiptId || params?.receiptId;
  const receiptType = props.receiptType || params?.receiptType;
  const receiptOrigin = props.receiptOrigin || params?.receiptOrigin;
  const movement = props.movement || params?.movement;

  const { data } = useGetReceipt({ receiptId, receiptType, receiptOrigin });

  const getReceipt = () => {
    switch (data.Type) {
      case "Payment":
        return <PaymentReceipt {...data.Payload} movement={movement} />;

      case "P2P":
        return <P2PReceipt {...data.Payload} movement={movement} />;

      case "TED":
        return <TedReceipt {...data.Payload} movement={movement} />;

      case "Pix":
        return <PixReceipt {...data.Payload} movement={movement} />;

      default:
        return <NotFound />;
    }
  };

  useCheckAppVersion();

  return getReceipt();
};
