import axios from "axios";
import { IP_SERVICE_KEY } from "../../../config";

export interface DeviceInfo {
  deviceId: string;
  deviceModel: string;
  operatingSystem: string;
}

export interface DeviceFingerprint {
  generateDeviceId(): Promise<string>;
  getDeviceInfo(): Promise<DeviceInfo>;
}

export interface ClientIpInfo {
  ip: string;
  ipType: "IPv4" | "IPv6";
  os: string;
  device: string;
  userAgent: string;
}

type DebugInfo = {
  UNMASKED_VENDOR_WEBGL: number;
  UNMASKED_RENDERER_WEBGL: number;
};

export class DeviceFingerprintService implements DeviceFingerprint {
  constructor(
    private readonly accountId: string,
    private readonly email: string,
  ) {}

  async generateDeviceId(): Promise<string> {
    const stableFeatures = await this.collectStableFeatures();

    const deviceData = {
      accountId: this.accountId,
      email: this.email,
      stableFeatures,
    };

    const hash = await this.hashData(JSON.stringify(deviceData));
    return hash.slice(0, 32); // Return first 32 characters of hash
  }

  private async collectStableFeatures() {
    return {
      hardware: {
        cores: navigator.hardwareConcurrency || 0,
        memory: (navigator as any).deviceMemory || 0,
        platform: navigator.platform,
      },
      browser: {
        language: navigator.language,
      },
      os: this.getOperatingSystem(),
      gpu: await this.getStableGPUInfo(),
      timezone: {
        offset: new Date().getTimezoneOffset(),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
  }

  private async getStableGPUInfo(): Promise<string> {
    if (!("HTMLCanvasElement" in window)) {
      return "webgl-not-supported";
    }

    const canvas = document.createElement("canvas");
    let gl: WebGLRenderingContext | null;

    try {
      gl =
        canvas.getContext("webgl", {
          failIfMajorPerformanceCaveat: true,
        }) ||
        (canvas.getContext("experimental-webgl", {
          failIfMajorPerformanceCaveat: true,
        }) as WebGLRenderingContext | null);
    } catch {
      return "webgl-not-available";
    }

    if (!gl) return "webgl-not-available";

    const debugInfo = gl.getExtension(
      "WEBGL_debug_renderer_info",
    ) as DebugInfo | null;
    if (!debugInfo) return "debug-info-not-available";

    try {
      const vendor =
        gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) || "unknown";
      const renderer =
        gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || "unknown";
      return `${vendor}-${renderer}`;
    } catch {
      return "gpu-info-protected";
    }
  }

  private async hashData(data: string): Promise<string> {
    try {
      const encoder = new TextEncoder();
      const encoded = encoder.encode(data);
      const hashBuffer = await crypto.subtle.digest("SHA-256", encoded);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    } catch (error) {
      console.error("Error hashing data:", error);
      return `hash-error-${Date.now()}`;
    }
  }

  async getDeviceInfo(): Promise<DeviceInfo> {
    const deviceId = await this.generateDeviceId();

    return {
      deviceId,
      deviceModel: navigator.platform || "",
      operatingSystem: this.getOperatingSystem(),
    };
  }

  private getOperatingSystem(): string {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    if (/Win/.test(platform)) return "Windows";
    if (/Mac/.test(platform)) return "MacOS";
    if (/Linux/.test(platform)) return "Linux";
    if (/Android/.test(userAgent)) return "Android";
    if (/iPhone|iPad|iPod/.test(userAgent)) return "iOS";

    return "Unknown";
  }
}
