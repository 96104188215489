import { zodResolver } from "@hookform/resolvers/zod";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Button, Checkbox, Select, TextField } from "@hyperlocal/vital2";
import { format, subDays } from "date-fns";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

const filtersSchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  transactionType: z.string().optional(),
  transactionStatus: z.array(z.string()).optional(),
  beneficiaryName: z.string().optional(),
});

export type FiltersSchema = z.infer<typeof filtersSchema>;

const formatDate = (date: Date) => format(date, "yyyy-MM-dd");
const parseDefaultValues = (params: URLSearchParams) => {
  const defaultValues = {
    startDate: formatDate(subDays(new Date(), 7)),
    endDate: formatDate(new Date()),
  };

  return {
    startDate: params.get("startDate") || defaultValues.startDate,
    endDate: params.get("endDate") || defaultValues.endDate,
    transactionType: params.get("transactionType") || "",
    beneficiaryName: params.get("beneficiaryName") || "",
    transactionStatus: params.getAll("transactionStatus"),
  };
};

export const FiltersContent = () => {
  const [params, setParams] = useSearchParams();
  const isMobile = Hooks.useMediaQuery("mobile");

  const methods = useForm<FiltersSchema>({
    resolver: zodResolver(filtersSchema),
    defaultValues: parseDefaultValues(params),
  });

  const { handleSubmit, control, watch, reset, setValue, register } = methods;

  const onSubmit = (values: FiltersSchema) => {
    params.delete("transactionStatus");

    values.transactionStatus.forEach((status) => {
      setParams((params) => {
        params.append("transactionStatus", status);
        return params;
      });
    });

    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        setParams((params) => {
          if (typeof value === "string") {
            params.set(key, value);
          }

          return params;
        });
      }
    });
  };

  const handleClearFilters = () => {
    [
      "startDate",
      "endDate",
      "transactionType",
      "transactionStatus",
      "beneficiaryName",
      "showFilters",
    ].forEach((key) =>
      setParams((params) => {
        params.delete(key);
        return params;
      }),
    );

    reset();
  };

  const handleCheckStatus = (checked: boolean, newStatus: string) => {
    const previousValues = watch("transactionStatus");

    if (checked) {
      setValue(
        "transactionStatus",
        previousValues ? [...previousValues, newStatus] : [newStatus],
      );

      return;
    }

    setValue(
      "transactionStatus",
      previousValues ? [...previousValues.filter((s) => s !== newStatus)] : [],
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        className="flex h-full flex-col justify-between mobile:p-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-[24px]">
          <div className="space-y-[16px]">
            <h4 className="text-xs font-bold">Período personalizado</h4>

            <div className="grid grid-cols-2 gap-4">
              <Controller
                control={control}
                name="startDate"
                render={({ field }) => (
                  <div>
                    <TextField.Label htmlFor={field.name}>De</TextField.Label>

                    <TextField.Root>
                      <TextField.Input
                        type="date"
                        onChange={(event) => field.onChange(event.target.value)}
                        value={field.value}
                        id={field.name}
                        max={watch("endDate")}
                      />

                      <TextField.Slot name="GeralCalendar" />
                    </TextField.Root>
                  </div>
                )}
              />

              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <div>
                    <TextField.Label htmlFor={field.name}>Até</TextField.Label>

                    <TextField.Root>
                      <TextField.Input
                        type="date"
                        onChange={(event) => field.onChange(event.target.value)}
                        value={field.value}
                        min={watch("startDate")}
                        id={field.name}
                      />

                      <TextField.Slot name="GeralCalendar" />
                    </TextField.Root>
                  </div>
                )}
              />
            </div>
          </div>

          <div className="space-y-[16px]">
            <h4 className="text-xs font-bold">Tipo de lançamento</h4>

            <div className="space-y-[16px]">
              {/* <FiltersResponsible /> */}

              <Controller
                control={control}
                name="transactionType"
                render={({ field }) => (
                  <Select.Root
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <Select.Label htmlFor={field.name}>
                      Tipo de transação
                    </Select.Label>

                    <Select.Trigger className="w-full">
                      <Select.Value placeholder="Selecione..." />
                    </Select.Trigger>

                    <Select.Content>
                      <Select.Item value="TED">TED</Select.Item>
                      <Select.Item value="P2P">P2P</Select.Item>
                      <Select.Item value="Pix">Pix</Select.Item>
                      <Select.Item value="Payment">Pagamento</Select.Item>
                    </Select.Content>
                  </Select.Root>
                )}
              />

              <div className="space-y-[16px]">
                <TextField.Label htmlFor="beneficiaryName">
                  Favorecido
                </TextField.Label>

                <TextField.Root>
                  <TextField.Input
                    className=""
                    placeholder="Digite..."
                    {...register("beneficiaryName")}
                  />
                </TextField.Root>
              </div>

              <div className="space-y-[16px]">
                <Select.Label>Status</Select.Label>

                <div className="grid grid-cols-2 gap-2">
                  <div className="flex items-center">
                    <Checkbox.Slot
                      id="APPROVED"
                      onCheckedChange={(checked) =>
                        handleCheckStatus(Boolean(checked), "APPROVED")
                      }
                      checked={watch("transactionStatus").includes("APPROVED")}
                    />

                    <Checkbox.Label htmlFor="APPROVED">
                      Aprovados
                    </Checkbox.Label>
                  </div>

                  <div className="flex items-center">
                    <Checkbox.Slot
                      id="REPPROVED"
                      onCheckedChange={(checked) =>
                        handleCheckStatus(Boolean(checked), "REPPROVED")
                      }
                      checked={watch("transactionStatus").includes("REPPROVED")}
                    />
                    <Checkbox.Label htmlFor="REPPROVED">
                      Reprovados
                    </Checkbox.Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <Button.Root type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button.Root>

          <Button.Root
            variant="link"
            type="button"
            onClick={() => handleClearFilters()}
          >
            Limpar filtro
          </Button.Root>
        </div>
      </form>
    </FormProvider>
  );
};
