import type {
  CheckAppVersionUseCase,
  CheckAppVersionUseCaseInput,
  CheckAppVersionUseCaseOutput,
  VersionStorageKey,
} from "../../domain/use-cases";
import { VersionChecker } from "../services/versioning";

export class CheckAppVersionUseCaseImpl implements CheckAppVersionUseCase {
  private storageKey: VersionStorageKey;
  constructor(
    private readonly versionChecker: VersionChecker,
    storageKey: VersionStorageKey,
  ) {
    this.versionChecker.onVersionChange((newVersion) => {
      this.onCheckStorage(storageKey, newVersion);
    });
    this.storageKey = storageKey;
  }

  private async hotReload() {
    if ("caches" in window) {
      await caches
        .keys()
        .then((keys) => keys.forEach((key) => caches.delete(key)));
    }
    window.document.location.reload();
  }

  private onCheckStorage(storageKey: string, newVersion: string) {
    const versionStorage = sessionStorage.getItem(storageKey);

    if (!versionStorage) {
      sessionStorage.setItem(storageKey, newVersion);
      return;
    }

    if (versionStorage !== newVersion) {
      sessionStorage.setItem(storageKey, newVersion);
      this.hotReload();
    }
  }

  async execute(
    input: CheckAppVersionUseCaseInput,
  ): Promise<CheckAppVersionUseCaseOutput> {
    const version = await this.versionChecker.check(input.url);

    const versionStorage = sessionStorage.getItem(this.storageKey);

    if (!versionStorage) {
      sessionStorage.setItem(this.storageKey, version);
    }

    this.onCheckStorage(this.storageKey, version);

    return version;
  }
}
