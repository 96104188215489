import { useSuspenseTransaction } from "@/pages/pending-transactions/common";
import {
  TransactionStatus,
  TransactionType,
} from "@/services/types/get-pending-transactions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency, Hooks } from "@hyperlocal/banking-utility";
import { format, parseISO } from "date-fns";
import { twMerge } from "tailwind-merge";
import { SummaryReceipt } from "./summary-receipt";

const classNames = {
  label: "font-bold",
  value:
    "text-end text-neutral-dark whitespace-nowrap overflow-hidden text-ellipsis",
};

const statusDictionary: Record<TransactionStatus, string> = {
  APPROVED: "Aprovado",
  PENDING: "Pendente",
  REPPROVED: "Reprovada", // ??
  REPROVED: "Reprovada",
};

const typeDictionary: Record<TransactionType, string> = {
  P2P: "P2P",
  Payment: "Boleto",
  Pix: "Pix",
  TED: "TED",
};

type SummaryInfosProps = { transactionId: string };
export const SummaryInfos = ({ transactionId }: SummaryInfosProps) => {
  const { data } = useSuspenseTransaction(transactionId);

  const {
    TransactionStatus,
    BeneficiaryName,
    BankName,
    BankAccountNumber,
    BankAccountDigit,
    BankBranch,
    TransactionType,
    DateTransaction,
    Amount,
  } = data;

  const isDesktop = Hooks.useMediaQuery("desktop");

  return (
    <div className="flex h-full flex-1 flex-col justify-between">
      <div className="space-y-[24px]">
        {isDesktop && (
          <h5 className="text-[20px] font-bold text-neutral-darkest mobile:hidden">
            Lançamento
          </h5>
        )}

        <div className="grid grid-cols-2 gap-4">
          <div className={classNames.label}>Status da validação</div>
          <div className="flex justify-end">
            <div
              className={twMerge(
                "w-auto rounded-xs border px-2 py-0.5 text-end text-[14px] font-semibold",
                TransactionStatus === "APPROVED" &&
                  "border-status-success-dark bg-status-success-light text-status-success-dark",
                TransactionStatus === "PENDING" &&
                  "border-status-warning-dark bg-status-warning-light text-status-warning-dark",
                ["REPPROVED", "REPROVED"].includes(TransactionStatus) &&
                  "border-status-error-dark bg-status-error-light text-status-error-dark",
              )}
            >
              {statusDictionary[TransactionStatus]}
            </div>
          </div>

          <div className="col-span-2 mx-8 border-b" />

          <div className={classNames.label}>Favorecido</div>
          <div className={classNames.value} title={BeneficiaryName}>
            {BeneficiaryName}
          </div>

          <div className={classNames.label}>Banco</div>
          <div className={classNames.value} title={BankName}>
            {BankName}
          </div>

          <div className={classNames.label}>Agência</div>
          <div className={classNames.value}>{BankBranch}</div>

          <div className={classNames.label}>Conta</div>
          <div className={classNames.value}>
            {BankAccountNumber}-{BankAccountDigit}
          </div>

          <div className="col-span-2 mx-8 border-b" />

          <div className={classNames.label}>Data da transação</div>
          <div className={classNames.value}>
            {format(parseISO(DateTransaction), "dd/MM/yy HH:mm")}
          </div>

          <div className={classNames.label}>Tipo</div>
          <div className={classNames.value}>
            {typeDictionary[TransactionType]}
          </div>

          <div className="col-span-2 mx-8 border-b" />

          <div className="text-xs font-bold">Valor do lançamento</div>
          <div className="text-end text-sm font-bold text-primary-main">
            <span className="text-x2s">R$</span>
            {formatCurrency(Amount).replace("R$", "")}
          </div>
        </div>
      </div>

      {TransactionStatus === "APPROVED" && <SummaryReceipt receipt={data} />}
    </div>
  );
};
